<template>
  <div class="container-xl">
    <div class="row">
      <div class="col-sm-6 col-lg-3 cursor-pointer" @click="show.modal_histories = true" v-loading="loading.balance">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="subheader"></div>
            </div>
            <div class="h1 mb-3">Rp. {{ ewa_balance?.toLocaleString() || 0 }}</div>
            <div class="d-flex mb-2">
              <div>EWA Wallet Balance</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="subheader"></div>
            </div>
            <div class="h1 mb-3">{{ summary.total_request || 0 }}</div>
            <div class="d-flex mb-2">
              <div>Total EWA Requested</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="subheader"></div>
            </div>
            <div class="h1 mb-3">{{ summary.total_amount_str || '-' }}</div>
            <div class="d-flex mb-2">
              <div>Total EWA Amount</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="subheader"></div>
            </div>
            <div class="h1 mb-3">{{ summary.total_admin_fee_str || '-' }}</div>
            <div class="d-flex mb-2">
              <div>Total EWA Admin Fee</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card col-lg-12 mt-2">
      <b-tabs :value="current_active_index">
        <b-tab :active-id="index.toString()" v-for="(opt, index) in tab_options" :key="index" :title="opt.name" @click="handlerStatusFilter(opt.value)">
          <div class="card-header justify-content-between">
            <div class="card-title">
              EWA {{ opt.name }} List
            </div>
          </div>
          <div class="table-responsive">
            <table class="table card-table text-nowrap table-vcenter">
              <thead>
                <tr>
                  <th width="40%">Company Name</th>
                  <!-- del -->
                  <!-- <th width="40%">Bank Account Name</th>
                  <th width="40%">Bank Account Number</th>
                  <th width="40%">Bank ID</th> -->
                  <th width="10%">Total Recipient</th>
                  <th width="20%">Total Requested Amount</th>
                  <th width="20%">Total Admin Fee</th>
                  <th width="10%">#</th>
                </tr>
              </thead>
              <tbody v-loading="is_loading">
                <tr v-for="(item, i) in list" :key="i">
                  <td>{{ item.company.name }}</td>
                  <!-- <td>{{ item.bank_account_name }}</td>
                  <td>{{ item.bank_account_number }}</td>
                  <td>{{ item.bank_id }}</td> -->
                  <td>{{ item.total_request }} recipient(s)</td>
                  <td>{{ item.total_amount_str }}</td>
                  <td>{{ item.total_admin_fee_str }}</td>
                  <td>
                    <el-button round v-if="(isChecker && opt.value == 'waiting_disbursement') || (isMaker && opt.value == 'waiting_approval')" @click="handlerBtnApprove(item, opt.value)" type="success" size="mini">
                      <font-awesome-icon icon="check"/>
                    </el-button>
                    <el-button round v-if="isChecker && opt.value == 'waiting_disbursement'" size="mini" @click="handlerBtnReject(item, opt.value)" type="danger">
                      <font-awesome-icon icon="times-circle"/>
                    </el-button>
                    <el-button round size="mini" @click="handlerBtnView(item.company_id, opt.value, opt.value == 'waiting_approval' ? 'approved' : null)" type="info">
                      <font-awesome-icon icon="address-book"/>
                    </el-button>
                    <el-button v-if="opt.value == 'completed'" alt="View Invoice" round size="mini" @click="handlerBtnViewInvoice(item.company_id, item.company.name)" type="primary">
                      <font-awesome-icon icon="file-invoice"/>
                    </el-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <el-empty description="No EWA request for now" v-if="!total_rows" :image="emptyStateImage"></el-empty>
          </div>
          <pagination
            :page="page"
            :per_page="per_page"
            :list="list"
            :total_rows="total_rows"
            @pageChange="pageChangeHandler" />
        </b-tab>
      </b-tabs>
    </div>
    <b-modal size="md" v-model="show.modal_ewa" title="Approve EWA" hide-footer>
    <div v-if="disburse.auto_disburse" class="flex items-center p-2 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
      <svg class="shrink-0 inline w-3 h-3 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span class="sr-only">Info</span>
      <div>
        <span class="font-medium">Disbursed by system activated</span>
      </div>
    </div>

    <div v-else class="flex items-center p-2 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
      <svg class="shrink-0 inline w-3 h-3 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span class="sr-only">Info</span>
      <div>
        <span class="font-medium">Disbursement by system deactivated </span>
      </div>
    </div>
      <el-descriptions :loading="loading.modal_ewa" class="margin-top" title="" :column="1" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            Company Name
          </template>
          {{ selected_ewa.company_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i><font-awesome-icon icon="users" size="sm"/></i>
            Total EWA Request
          </template>
          {{ selected_ewa.total_request }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i><font-awesome-icon icon="money-bill-alt" size="sm"/></i>
            Total EWA Amount
          </template>
          {{ selected_ewa.total_amount_str }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i><font-awesome-icon icon="hand-holding-usd" size="sm"/></i>
            Total Admin Fee
          </template>
          {{ selected_ewa.total_admin_fee_str }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i><font-awesome-icon icon="money-bill-wave" size="sm"/></i>
            Total Disburse Amount
          </template>
          {{ selected_ewa.disburse_amount_str }}
        </el-descriptions-item>
        <!-- v-if="status_filter === 'waiting_disbursement'" -->
        <!-- <el-descriptions-item v-if="shouldDisplayPaymentReceiptForm">
          <template slot="label">
            <i><font-awesome-icon icon="money-bill-wave" size="sm"/></i>
            Auto Disbuse
          </template>
          {{ disburse.auto_disburse }}
        </el-descriptions-item> -->
        <el-descriptions-item v-if="shouldDisplayPaymentReceiptForm && !disburse.auto_disburse">
          <template slot="label">
            <i><font-awesome-icon icon="money-bill-wave" size="sm"/></i>
            Payment Confirmation Receipt
          </template>
          <b-form-file
            v-model="selected_file.payment_confirm_file"
            :state="Boolean(selected_file.payment_confirm_file)"
            class="mt-2 border-1 p-2"
            accept=".png, .jpg, .jpeg"
            @input="onChangeFile($event, 'payment_confirm_file')"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
            <img ref="payment_confirm_file" :src="test.payment_confirm_file"/>
        </el-descriptions-item>
      </el-descriptions>
      <div class="d-flex float-right mt-2">
        <el-button class="mr-2 ml-2" @click="submit_approval" size="small" type="primary">Submit</el-button>
        <el-button @click="show.modal_ewa = false" size="small" class="mr-2">Cancel</el-button>
      </div>
    </b-modal>
    <ewa-balance-histories :isShown="show.modal_histories"/>
  </div>
</template>
<script>
import {
  GET_EWALIST, INQUIRY_APPROVAL, SUBMIT_APPROVAL, ROLLBACK_APPROVAL, CHECK_EWA_BALANCE,
} from '@/store/modules/ewa';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
import Pagination from '../components/Pagination.vue';
import EwaBalanceHistories from './ewa/BalanceHistories.vue';
import imageCompress from '@/library/vue-image-compression';
import base64File from '@/library/base64File';

export default {
  name: 'EWA',
  components: {
    Pagination,
    EwaBalanceHistories,
  },
  metaInfo: {
    title: 'Earned Wage Access',
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    isTabWaitingActive() {
      return this.$route.query.tab === '';
    },
    isTabApproveActive() {
      return this.$route.query.tab === 'completed';
    },
    currentRole() {
      return this.$store.getters.current_role;
    },
    userProfile() {
      return this.$store.getters.currentMyProfile;
    },
    isMaker() {
      return this.userProfile.is_maker || this.currentRole.page_name === '*';
    },
    isChecker() {
      return this.userProfile.is_checker || this.currentRole.page_name === '*';
    },
    shouldDisplayPaymentReceiptForm() {
      return this.status_filter === 'waiting_disbursement' &&
            this.tab_options.some(tab => tab.value === 'waiting_disbursement');
    },
  },
  data() {
    return {
      disburse: {
        approval_id: "",
        total_amount: 0,
        total_admin_fee: 0,
        auto_disburse: false,
      },
      emptyStateImage,
      is_loading: false,
      total_rows: 0,
      page: 1,
      per_page: 20,
      list: [],
      show: {
        modal_ewa: false,
        modal_histories: false,
      },
      loading: {
        modal_ewa: false,
        balance: true,
      },
      selected_ewa: {},
      selected_file: {
        // promo_image_url: null,
        payment_confirm_file: null,  
        payment_confirm_file_base64: null,  
    },
    test: {
        payment_confirm_file: '',  
    },
      status_filter: 'waiting_approval',
      current_active_index: 0,
      tab_options: [
        {
          name: 'Waiting Approval',
          value: 'waiting_approval',
        },
        {
          name: 'Waiting Disbursement',
          value: 'waiting_disbursement',
        },
        {
          name: 'Approved',
          value: 'completed',
        },
      ],
      summary: {},
      ewa_balance: 0,
      file_tmp: '',
    };
  },
  beforeMount() {
    const i = this.tab_options.findIndex((v) => v.value === this.status_filter);
    this.current_active_index = i;
  },
  async mounted() {
    if (this.$route.query.tab) {
      this.status_filter = this.$route.query.tab;
    }
    await this.getList();
    this.ewaBalance();
  },
  methods: {
    async handlerStatusFilter(value) {
      this.status_filter = value;
      if (this.status_filter !== this.$route.query.tab) {
        this.$router.replace({
          name: 'EWA',
          query: {
            tab: value,
          },
        });
      }
      this.page = 1;
      this.list = [];
      this.total_rows = 0;
      this.is_loading = true;
      await this.getList();
      this.is_loading = false;
    },
    async pageChangeHandler(page) {
      this.page = page;
      this.is_loading = true;
      await this.getList();
      this.is_loading = false;
    },
    async getList() {
      this.$parent.showLoader();
      await this.$store.dispatch(GET_EWALIST, {
        page: this.page,
        status_filter: this.status_filter,
      });
      this.$parent.hideLoader();
      const { rows, count, summary } = this.$store.getters.ewa_list;
      this.current_active_index = this.tab_options.findIndex((v) => v.value === this.status_filter);
      this.total_rows = count;
      this.list = rows;
      this.summary = summary;
      this.summary.total_amount_str = `Rp. ${(summary.total_amount || 0).toLocaleString()}`;
      this.summary.total_admin_fee_str = `Rp. ${(summary.total_admin_fee || 0).toLocaleString()}`;
    },
    handlerBtnApprove(item, current_state) {
      this.show.modal_ewa = true;
      this.selected_ewa = item;
      this.selected_ewa.action = current_state === 'waiting_approval' ? 'waiting_disbursement' : 'disburse';
      this.selected_ewa.current_state = current_state;
      this.inquiry();
    },

    async inquiry() {
      console.log("Inquiry started");
      this.loading.modal_ewa = true;

      const ownedBy = this.selected_ewa?.owned_by ? this.selected_ewa.owned_by.toString() : "";

      const payload = {
        id: this.selected_ewa.company_id,
        query: {
          current_state: this.selected_ewa.current_state,
        },
      };
      console.log("Payload being sent:", JSON.stringify(payload, null, 2));
      try {
        const response = await this.$store.dispatch(INQUIRY_APPROVAL, payload);
        console.log("Response received:", response);
        this.disburse = {
          approval_id: response.approval_id,
          total_amount: response.total_amount,
          total_admin_fee: response.total_admin_fee,
          auto_disburse: response.auto_disburse,
        };
        console.log("Updated disburse data:", JSON.stringify(this.disburse, null, 2));
        // Update selected_ewa approval_id
        this.selected_ewa.approval_id = response.approval_id;
      } catch (error) {
        console.error("Error during inquiry:", error);
      } finally {
        this.loading.modal_ewa = false;
        console.log("Inquiry process completed");
      }
    },
    async submit_approval() {
      if (
        this.shouldDisplayPaymentReceiptForm &&
        !this.disburse.auto_disburse && 
        !this.selected_file.payment_confirm_file
      ) {
        this.$message.error('Please upload the Payment Confirmation Receipt file.');
        return;
      }
      this.$confirm(
        'You will approve this EWA disbursement batch. You cannot undo this action. Continue?',
        'Confirmation',
        {
          type: 'info',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              try {
                let data = {
                  approval_id: this.selected_ewa.approval_id,
                };

                if (this.status_filter === 'waiting_disbursement' && !this.disburse.auto_disburse) {
                  // Only add payment_confirm_file if auto_disburse is false
                  data.payment_confirm_file = this.file_tmp;
                }

                await this.$store.dispatch(SUBMIT_APPROVAL, {
                  id: this.selected_ewa.company_id,
                  data: data,
                });

                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Approval success',
                });

                this.show.modal_ewa = false;
                this.getList();
              } catch (err) {
                if (err.response?.data) {
                  popupErrorMessages(err.response.data);
                }
              }
              instance.confirmButtonLoading = false;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    async onChangeFile(event, target) {
      console.log('target ==>', target)
      if (this.selected_file[target]) {
        const compressed = await imageCompress(this.selected_file[target]);
        this.selected_file[`${target}_base64`] = await base64File(compressed);
        this.file_tmp = this.selected_file[`${target}_base64`]
        // try {
        //   this.$refs[`current_${target}`].src = this.selected_file[`${target}_base64`];
        //   this.form[`current_${target}`] = this.selected_file[`${target}_base64`];
        //   this.form[target] = this.form[`current_${target}`];
        // } catch (error) {
        //   console.log(error);
        // }
      }
    },
    async handlerBtnView(company_id, status, approval_status) {
      this.$router.push({
        name: 'EWA Detail',
        params: {
          id: company_id,
          status,
        },
        query: {
          approval_status,
        },
      });
    },
    handlerBtnReject(item, current_state) {
      this.$confirm('You will reject this whole request. You can not undo this action. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(ROLLBACK_APPROVAL, {
              id: item.company_id,
              current_state,
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Rejection success',
              });
              this.getList();
            }).catch((err) => {
              if (err.response?.data) popupErrorMessages(err.response.data);
            });
            instance.confirmButtonLoading = false;
          }
          done();
        },
      }).catch(() => {});
    },
    handlerBtnViewInvoice(company_id, company_name) {
      this.$router.push({
        name: 'EWA Invoice',
        params: {
          id: company_id,
          company_name,
        },
      });
    },
    async ewaBalance() {
      this.loading.balance = true;
      const { data } = await this.$store.dispatch(CHECK_EWA_BALANCE);
      this.ewa_balance = data.balance.balance;
      this.loading.balance = false;
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}
</style>